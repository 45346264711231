function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

function snapToPanel(objPosition, offset) {
    let linesArr = []
    let line = 0.15
    let lineBottom = -1.582234

    for (let i = 0; i <= 25; i++) {
        linesArr.push(round(lineBottom + line * i, 6))
    }

    linesArr.sort((a, b) => {
        return Math.abs(objPosition - a) - Math.abs(objPosition - b);
    })

    return linesArr[0];
}

function clamp(number, min, max) {
    return Math.max(min, Math.min(number, max));
}



function updateModelLimits(object) {
    object.userData.update = function () {
        object.position.clamp(object.userData.limit.min, object.userData.limit.max);
    }
}

function oControlsSettings(oControls) {
    oControls.enablePan = false;
    oControls.rotateSpeed = 0.025; // 0.025
    oControls.enableZoom = true;
    oControls.zoomToCursor = true;
    oControls.maxDistance = 8.5;
    oControls.minDistance = 2;
    oControls.enableDamping = true;
    oControls.dampingFactor = .05;
    oControls.maxPolarAngle = Math.PI / 2;
    oControls.minPolarAngle = Math.PI / 2;
    oControls.minAzimuthAngle = -(Math.PI / 20) // 20
    oControls.maxAzimuthAngle = (Math.PI / 20)  // 20
    oControls.enableRotate = false;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function loadInterfaceModel(model, scene, gLoader) {
    if (model === 'button_bin') {
        gLoader.load(
            "images/" + model + ".glb",
            function (gltf) {
                binButtonModel = gltf.scene
                binButtonModel.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name === 'buttonwhite') {
                            child.material = materials.whiteShinyMat;
                        }
                        if (child.name === 'bin') {
                            child.material = materials.redShinyMat;
                        }
                        child.castShadow = false;
                        child.receiveShadow = true;
                    }
                });
                scene.add(binButtonModel)
            },
            function (xhr) { },
            function (error) { }
        );
    }
}


export {
    round,
    updateModelLimits,
    snapToPanel,
    oControlsSettings,
    getRandomInt,
    clamp,
    loadInterfaceModel,
}