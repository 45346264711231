import.meta.glob([
    '../images/**',
    '../fonts/**',
    '../three/**',
]);

import './bootstrap';
import './accordion';
import './order';
import './contextMenu';
import * as screenshot from './screenshot';
import * as measurements from './measurements';
import * as fullscreen from './fullscreen';
import * as THREE from 'three';
import * as materials from './materials';
import * as lights from './lights';
import * as functions from './functions';
import { gsap } from "gsap";
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { DragControls } from './DragControls';
import { OrbitControls } from './OrbitControls';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { InteractionManager } from 'three.interactive';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { Text } from 'troika-three-text'
import 'ldrs/ring';



// mobile setup
let mobile = 'ontouchstart' in window || navigator.maxTouchPoints

if (mobile) {
    const mobileOrientation = window.matchMedia("(orientation: landscape)").matches;
    if (mobileOrientation) {
        $('.mobile-landscape-modal').removeClass('active')
    } else {
        $('.mobile-landscape-modal').addClass('active')
    }
}

// Check if there is configuration to be loaded
let configurationToLoad = false
$(document).ready(function () {
    if (getUrlParameter('config')) {
        configurationToLoad = true
        Livewire.dispatch('get-hash', { hash: getUrlParameter('config') })
        getConfigurationData(getUrlParameter('config'))
    } else {
        Livewire.dispatch('activate-app')
    }
});


THREE.Cache.enabled = true; // set to true for production

// Global variables init
let camera,
    dragInstance = {},
    scene,
    renderer,
    wallSwitch = false,
    isObjectHovered = false,
    objectControlsActive = false,
    objectsVisible = true,
    mixer,
    introAnimation = true,
    addedObjects = [],
    uiRotate,
    ModelloadingInProgress = false,
    wallsArray = [],
    propsGroup = new THREE.Group(),
    miniScene = new THREE.Scene(),
    miniModel,
    rightProps,
    objectsMoveConstraints = [],
    clipBtnExists = false,
    binButtonModel,
    configModelsCount = 0,
    configModelsLoaded = 0,
    cutButtonModel,
    activePanelColor = '4F5358',
    pointer = new THREE.Vector2(),
    loadingManager = new THREE.LoadingManager(),
    dLoader = new DRACOLoader(),
    gLoader = new GLTFLoader(loadingManager),
    globalColorVariation = 'steel',
    nightLights = [],
    configurationHash = '',
    userAuthorized = sessionStorage.getItem('authorization') ? sessionStorage.getItem('authorization') : '0',
    configurationsArray = JSON.parse(sessionStorage.getItem('configurations')) ? JSON.parse(sessionStorage.getItem('configurations')) : []


Livewire.on('unlock-configuration', ({ hash }) => {
    configurationHash = hash
    Alpine.store('conForm').toggle()
    Alpine.store('auth').toggle()
    Alpine.store('confNo').val = hash
    Livewire.dispatch('get-conf-number', { confNumber: hash })
    // gsap.from('#main_ui', {
    //     opacity: 0,
    //     scale: .8,
    //     duration: .5,
    //     delay: .5
    // })
})

document.addEventListener('alpine:init', () => {

    // if app is loaded in an iframe
    Alpine.store('external', {
        on: false,
        toggle() { this.on = !this.on }
    })

    // information about where iframe is located
    Alpine.store('externalDestination', {
        val: ''
    })

    // allow eshop features
    Alpine.store('allowShop', {
        on: true,
        toggle() { this.on = !this.on }
    })

    Alpine.store('conForm', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('confNo', {
        val: ''
    })

    Alpine.store('saveReminder', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('confSettings', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('cartModal', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('auth', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('uiSearchState', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('mobileMenu', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('mobileCart', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('mobileControls', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('colorBar', {
        on: false,
        toggle() {
            this.on = !this.on

            if (this.on) {
                $('#color-panel-toggle').html('Zamknij')
            } else {
                $('#color-panel-toggle').html('Zmień schemat kolorów')
            }
        }
    })

    Alpine.effect(() => {
        // Update the button text when colorBar.on changes
        $('#color-panel-toggle').text(
            Alpine.store('colorBar').on ? 'Zamknij' : 'Zmień schemat kolorów'
        );
    });

    // customizes UI if app is loaded in an iframe
    const urlParams = new URLSearchParams(window.location.search);
    const iframeDestination = urlParams.get('destination');
    const iframeShop = urlParams.get('shop');

    if (iframeDestination !== null) {
        Alpine.store('external').on = true
        Alpine.store('allowShop').on = false
        Alpine.store('externalDestination').val = iframeDestination

        if (iframeShop !== null && iframeShop === 'true') {
            Alpine.store('allowShop').on = true
        }
    }
    console.log('Store is: ' + Alpine.store('allowShop').on + ', Destination is: ' + Alpine.store('externalDestination').val)
})

// THREE Loading manager functions
loadingManager.onStart = function (url, item, total) { }
loadingManager.onProgress = function (url, loaded, total) {
    document.getElementById('progress-bar').value = (loaded / total) * 100
}
loadingManager.onLoad = function () {
    Livewire.dispatch('load-objects-data')
    if (introAnimation) {
        setTimeout(function () {
            if (configurationToLoad) {
                setTimeout(() => {
                    $('.progress-bar-container').fadeOut('slow')
                }, 1000);
            } else {
                $('.progress-bar-container').fadeOut('slow')
            }

            Livewire.dispatch('play-intro-animation')
        }, 2500); // 2500
    }
    introAnimation = false

    // Cookiebot live domain script
    // var script = document.createElement('script');
    // script.id = 'Cookiebot';
    // script.src = 'https://consent.cookiebot.com/uc.js';
    // script.setAttribute('data-cbid', '1250c3ae-7c64-4825-929f-f752a20e4eea');
    // script.setAttribute('data-blockingmode', 'auto');
    // document.head.appendChild(script);
    // if (mobile) {
    //     $('#CookiebotWidget').css('display', 'none !important')
    // }
}

// Draco loader setup
dLoader.setDecoderPath('js/draco/');
gLoader.setDRACOLoader(dLoader)

// Viewport dimensions
const viewport = {
    width: window.innerWidth,
    height: window.innerHeight
}



// Mousemove position tracking
function onPointerMove(event) {
    pointer.x = (event.clientX / viewport.width) * 2 - 1;
    pointer.y = - (event.clientY / viewport.height) * 2 + 1;
    pointer.xNorm = event.clientX;
    pointer.yNorm = event.clientY;
}

// Miniscene for product detail modal
function miniSceneInit(model) {

    const miniViewport = {
        width: $("#mini_scene").width(),
        height: $("#mini_scene").height()
    }

    const miniSceneContainer = document.getElementById('mini_scene');

    const miniRenderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    miniRenderer.setSize(miniViewport.width, miniViewport.height);
    miniRenderer.capabilities.getMaxAnisotropy()
    miniRenderer.capabilities.getMaxPrecision()
    //miniRenderer.setClearColor(0xf7f7f7, 1);
    miniRenderer.shadowMap.enabled = true
    miniRenderer.shadowMap.autoUpdate = true;
    miniRenderer.shadowMap.needsUpdate = true;
    miniRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
    miniRenderer.outputColorSpace = THREE.SRGBColorSpace;
    miniRenderer.toneMapping = THREE.ReinhardToneMapping;
    miniRenderer.toneMappingExposure = 2.3;
    miniSceneContainer.appendChild(miniRenderer.domElement);

    // hdr lightning
    new RGBELoader().setPath('images/').load('hdr_mini.hdr', function (hdrmap) {
        hdrmap.mapping = THREE.EquirectangularReflectionMapping;
        miniScene.environment = hdrmap
    })

    // camera
    const miniCamera = new THREE.PerspectiveCamera(35, miniViewport.width / miniViewport.height, 0.1, 1000);
    miniCamera.position.z = 1;
    miniCamera.position.y = 0;

    // lights
    miniScene.add(lights.hemiLight);

    model = model.replace('.glb', '')
    loadMiniModel(model)

    function loadMiniModel(model) {
        gLoader.load(
            "images/" + model + ".glb",
            function (gltf) {

                miniModel = gltf.scene

                let miniScale = 1.5;
                miniModel.scale.set(miniScale, miniScale, miniScale);

                miniModel.rotation.y = 0;

                miniModel.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name.indexOf('metal') !== -1) {
                            child.material = materials.miniMetalMat;
                        }
                        if (child.name.indexOf('rubber') !== -1) {
                            child.material = materials.miniRubberMat;
                        }
                        if (child.name === 'model') {
                            child.material = materials.objectMat;
                            gsap.to(child.material, { opacity: 1, duration: 0.4 })
                        }
                        if (child.name === 'hbox') {
                            child.material = materials.hoverMat;
                        }
                        if (child.name === 'fortis') {
                            child.material = materials.miniFortisMat;
                        }
                        if (child.name.indexOf('plastic') !== -1) {
                            let plasticMaterial = new THREE.MeshPhysicalMaterial({
                                color: 0xBEBEBE,
                                reflectivity: .6,
                                roughness: 0.25,
                                clearcoat: .15,
                                clearcoatRoughness: 0.15,
                            })
                            child.material = plasticMaterial;
                        }
                        if (child.name !== 'hbox') {
                            child.castShadow = true;
                            child.receiveShadow = true;
                        }
                    }
                });

                miniModel.userData.type = "model"
                miniScene.add(miniModel);
            },

            function (xhr) {
                Livewire.dispatch('mini-model-loading', xhr.loaded / xhr.total * 100)
            },
            function (error) { }
        );
    };

    // animate
    function animateMini() {
        requestAnimationFrame(animateMini);
        if (miniModel) {
            miniModel.rotation.y += 0.0025
        }
        miniCamera.updateProjectionMatrix();
        miniRenderer.render(miniScene, miniCamera);
    }

    animateMini();

    window.addEventListener('resize', () => {
        miniViewport.width = $("#mini_scene").width()
        miniViewport.height = $("#mini_scene").height()
        miniCamera.aspect = miniViewport.width / miniViewport.height
        miniCamera.updateProjectionMatrix()
        miniRenderer.setSize(miniViewport.width, miniViewport.height)
    })
}

Livewire.on('load-mini-scene', ({ model }) => {
    miniSceneInit(model)
})

Livewire.on('reset-miniscene', () => {
    miniModel.rotation.y = 0
    miniScene.remove(miniModel)
    $("#mini_scene  > canvas").remove();
})


// main scene init
scene = new THREE.Scene();

if (mobile) {
    // renderer
    renderer = new THREE.WebGLRenderer({ alpha: false, antialias: true, preserveDrawingBuffer: true, powerPreference: 'low-power' });
    renderer.setSize(viewport.width, viewport.height);
    renderer.setPixelRatio(1)
    renderer.setClearColor(0xffffff, 1);
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true
    renderer.shadowMap.autoUpdate = true;
    renderer.shadowMap.needsUpdate = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.toneMappingExposure = 3.85;
    renderer.localClippingEnabled = true;
    document.body.prepend(renderer.domElement);
} else {
    // renderer
    renderer = new THREE.WebGLRenderer({ alpha: false, antialias: true, preserveDrawingBuffer: true, powerPreference: 'low-power' });
    renderer.setSize(viewport.width, viewport.height);
    renderer.setPixelRatio(1)

    renderer.capabilities.getMaxAnisotropy()
    renderer.capabilities.getMaxPrecision()
    renderer.setClearColor(0xffffff, 1);
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true
    renderer.shadowMap.autoUpdate = true;
    renderer.shadowMap.needsUpdate = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.toneMappingExposure = 3.85;
    renderer.localClippingEnabled = true;
    document.body.prepend(renderer.domElement);
}



// environment HDR map
let standaloneHDR
new RGBELoader().setPath('images/').load('hdr3.hdr', function (hdrmap) {
    hdrmap.mapping = THREE.EquirectangularReflectionMapping;
    scene.environment = hdrmap
    standaloneHDR = hdrmap
})

// camera
camera = new THREE.PerspectiveCamera(35, viewport.width / viewport.height, 0.1, 1000);
// camera production values
camera.position.z = 8.5;
camera.position.y = -2;

// camera wip values

// orbit controls for slight camera movement on mousemove
const oControls = new OrbitControls(camera, renderer.domElement);
functions.oControlsSettings(oControls)

if (mobile) {
    oControls.screenSpacePanning = true;
    oControls.touches.ONE = THREE.TOUCH.PAN;
    oControls.touches.TWO = THREE.TOUCH.DOLLY_ROTATE;
    oControls.minAzimuthAngle = 0
    oControls.maxAzimuthAngle = 0
}


// PRODUCT SCREENSHOT MODE
// oControls.maxPan = new THREE.Vector3(0, 2, 8.75);
// oControls.minPan = new THREE.Vector3(2, -2, 0);
// oControls.enablePan = false;
// oControls.minAzimuthAngle = -(Math.PI / .2) // 20
// oControls.maxAzimuthAngle = (Math.PI / .2)  // 20
// oControls.rotateSpeed = 0;
// zoomInTimeline(-2, 0, 6, 1)
// gsap.to(camera, { fov: 35, duration: 1 })


// interaction manager init
const interactionManager = new InteractionManager(renderer, camera, renderer.domElement);

// lights

if (mobile) {
    lights.directLight.shadow.mapSize.width = 1024;
    lights.directLight.shadow.mapSize.height = 1024;
}

scene.add(lights.ambiLight);
scene.add(lights.hemiLight);
scene.add(lights.directLight);



// INITIAL ANIMATED PANELS
let lastWallX = 1.86
gLoader.load(
    "images/panel_animation2.glb",
    function (gltf) {
        let s = .05;
        gltf.scene.scale.set(s, s, s);

        gltf.scene.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
                if (child.name.indexOf('panel') !== -1) {
                    child.material = materials.panelSurface;
                    child.material.envMap = standaloneHDR;
                }
                if (child.name.indexOf('metal') !== -1) {
                    child.material = materials.metalMat;
                }
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        // remove metal wall parts from the initial wallset after animation is finished
        setTimeout(function () {
            gltf.scene.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    if (child.name.indexOf('metal') !== -1) {
                        child.visible = false;
                    }
                }
            });
        }, 10000);

        mixer = new THREE.AnimationMixer(gltf.scene);

        Livewire.on('play-intro-animation', () => {
            setTimeout(function () {
                for (let i = 0; i <= 107; i++) {
                    let panel_animation = mixer.clipAction(gltf.animations[i]);
                    panel_animation.setLoop(THREE.LoopOnce)
                    panel_animation.clampWhenFinished = true;
                    if (configurationToLoad) {
                        panel_animation.time = 60
                        panel_animation.play();
                    } else {
                        panel_animation.play();
                    }

                }
            }, 500);
        })

        gltf.scene.position.z = 0.13
        gltf.scene.position.y = 0.165
        gltf.scene.position.x = 0

        // control color
        let tempColor = new THREE.Color().setHSL(0, 0, 0);
        $('.change-panel-color-btn').on('click', function () {
            //e.preventDefault()
            let colorName = $(this).data('name');
            tempColor.setHex('0x' + $(this).data('color'));
            let newColor = $(this).data('color')
            activePanelColor = newColor
            scene.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    if (child.name.indexOf('panel') !== -1) {
                        if (newColor === '989EA2') { // dark wood
                            child.material = materials.darkWoodSurface
                            gsap.to(materials.panelSurface.color, {
                                r: tempColor.r,
                                g: tempColor.g,
                                b: tempColor.b,
                                duration: .25
                            })
                        }
                        if (newColor === '989EA3') { // light wood
                            child.material = materials.lightWoodSurface
                            gsap.to(materials.panelSurface.color, {
                                r: tempColor.r,
                                g: tempColor.g,
                                b: tempColor.b,
                                duration: .25
                            })
                        }
                        if (newColor !== '989EA3' && newColor !== '989EA2') {
                            child.material = materials.panelSurface
                            gsap.to(child.material.color, {
                                r: tempColor.r,
                                g: tempColor.g,
                                b: tempColor.b,
                                duration: .25
                            })
                        }

                    }
                }
            });
            $('#color-preview-ico').css('background-color', "#" + $(this).data('color'))
            Livewire.dispatch('panel-color-change', { shop_id: $(this).data('id'), color: colorName })
        })

        // adding wall to wallsArray for further manipulation and organization
        let firstWallBoundingBox = new THREE.Box3().setFromObject(gltf.scene)
        firstWallBoundingBox.applyMatrix4(scene.matrixWorld)

        lastWallX = firstWallBoundingBox.max.x

        let wall = {
            'id': 1,
            'size': 2,
            'xMin': -1.96,
            'xMax': 1.96,
            'group': new THREE.Group()
        }

        wall.group.userData.switched = false

        wall.group.add(gltf.scene)
        wallsArray.push(wall)

        scene.add(wall.group)

        oControls.maxPan = new THREE.Vector3(lastWallX - 1.9718, 0, 0);
        oControls.enablePan = true;

        let timeOut
        configurationToLoad ? timeOut = 0 : timeOut = 10000

        setTimeout(() => {
            createClipBtn(wall.xMin, wall.xMax, wall.group)
        }, timeOut);

    },
    function (xhr) { },
    function (error) { }
);

gLoader.load(
    'images/interior.glb',
    function (gltf) {

        let interiorModel = gltf.scene
        let s = 1.8
        interiorModel.scale.set(s, s, s)
        interiorModel.position.y = 0.15
        interiorModel.position.z = 0.1 // -3.5

        interiorModel.traverse((child) => {
            if (child.isMesh) {
                child.material = materials.interiorMat;
                //child.material.emissive = new THREE.Color().setHex(0x333333);
            }
            if (child.name === 'right') {
                rightProps = child
            }
            child.castShadow = true;
            child.receiveShadow = true;

        });

        interiorModel.name = 'interior'

        scene.add(interiorModel)

        Livewire.on('play-intro-animation', () => {
            gsap.from(camera, { fov: 60, duration: 2.5, ease: "power3.out" })
            //gsap.to(interiorModel.position, { z: 0.1, duration: 2.5, ease: "power3.out" })
        })
    },
    function (xhr) { },
    function (error) { }
);

function loadInterfaceModel(model) {
    if (model === 'button_bin') {
        gLoader.load(
            "images/" + model + ".glb",
            function (gltf) {
                binButtonModel = gltf.scene
                binButtonModel.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name === 'buttonwhite') {
                            child.material = materials.whiteShinyMat;
                        }
                        if (child.name === 'bin') {
                            child.material = materials.redShinyMat;
                        }
                        child.castShadow = false;
                        child.receiveShadow = true;
                    }
                });
                scene.add(binButtonModel)
            },
            function (xhr) { },
            function (error) { }
        );
    }
    if (model === 'button_cut') {
        gLoader.load(
            "images/" + model + ".glb",
            function (gltf) {
                cutButtonModel = gltf.scene
                cutButtonModel.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name === 'blueButtonBg') {
                            child.material = materials.objMetalDark;
                        }
                        if (child.name === 'cut_ico') {
                            child.material = materials.whiteShinyMat;
                        }
                        child.castShadow = false;
                        child.receiveShadow = true;
                    }
                });
                scene.add(cutButtonModel)
            },
            function (xhr) { },
            function (error) { }
        );
    }
}

loadInterfaceModel('button_bin')
loadInterfaceModel('button_cut')

let lastWall

// function to load additional panel sets(walls)
async function loadWall(size) {
    gLoader.load(
        "images/wall_" + size + "m.glb",
        function (gltf) {
            let s = .05;

            gltf.scene.scale.set(s, s, s);

            gltf.scene.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    if (child.name.indexOf('panel') !== -1) {
                        if (activePanelColor === '989EA2') { // dark wood
                            child.material = materials.darkWoodSurface
                        }
                        if (activePanelColor === '989EA3') { // light wood
                            child.material = materials.lightWoodSurface
                        }
                        if (activePanelColor !== '989EA3' && activePanelColor !== '989EA2') {
                            child.material = materials.panelSurface;
                        }
                    }
                    if (child.name.indexOf('metal') !== -1) {
                        child.material = materials.panelSurface;
                    }
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            gltf.scene.position.z = 0.13
            gltf.scene.position.y = 0.165
            gltf.scene.name = 'wall'

            // get last wall properties in wallsArray in order to place next wall properly
            // 2m wall size = 3.9437
            // 1m wall size = 1.9718
            // sideBarWidth = .06

            lastWall = wallsArray[wallsArray.length - 1]
            let lastWallBoundingBox = lastWall.xMax
            let lastWallGroupBoundingBox = new THREE.Box3().setFromObject(lastWall.group)
            //lastWallBoundingBox.applyMatrix4(scene.matrixWorld)

            gltf.scene.position.x = 1.9718 * (size / 2) + lastWallBoundingBox - 0.06
            //gltf.scene.position.x = 1.9718 * (size / 2) + lastWallBoundingBox.max.x - 0.06

            lastWallX = lastWallBoundingBox + (1.9718 * size) - 0.12
            oControls.enablePan = true;

            // get minX and maxX of this wall
            let thisWallBoundingBox = new THREE.Box3().setFromObject(gltf.scene)
            thisWallBoundingBox.applyMatrix4(scene.matrixWorld)
            let wallCenterX = thisWallBoundingBox.min.x + (thisWallBoundingBox.max.x - thisWallBoundingBox.min.x) / 2
            let wallCenterY = thisWallBoundingBox.min.y + (thisWallBoundingBox.max.y - thisWallBoundingBox.min.y) / 2

            gsap.to(oControls.target, { x: wallCenterX, y: wallCenterY, duration: 1.5, ease: "power2.inOut" })
            gsap.to(camera.position, { x: wallCenterX, y: wallCenterY, duration: 1.5, ease: "power2.inOut" })
            gsap.to(rightProps.position, { x: rightProps.position.x + 1.9718 * (size / 2), duration: 1.5, ease: "power2.inOut" })

            // add delete button object
            let deleteBtnMOdel = binButtonModel.clone()
            deleteBtnMOdel.position.x = thisWallBoundingBox.min.x + (thisWallBoundingBox.max.x - thisWallBoundingBox.min.x) / 2
            deleteBtnMOdel.position.y = thisWallBoundingBox.min.y
            deleteBtnMOdel.position.z = .25
            deleteBtnMOdel.name = 'delete'

            // adding wall to wallsArray for further manipulation and organization

            let wall = {
                'id': functions.getRandomInt(2, 9999),
                'size': size,
                'xMin': thisWallBoundingBox.min.x,
                'xMax': thisWallBoundingBox.max.x,
                'group': new THREE.Group()
            }

            wall.group.userData.switched = false

            wall.group.add(deleteBtnMOdel)
            wall.group.add(gltf.scene)

            wallsArray.push(wall)

            scene.add(wall.group)


            // update clipping plane
            updateClipPlane(wall.xMin, wall.xMax, wall.group)

            // add wall delete button
            let deleteBtn = wall.group.getObjectByName('delete')
            interactionManager.add(deleteBtn);

            // add lights for night mode
            for (let i = 0; i < size; i++) {
                let lastNightLight = nightLights[nightLights.length - 1] // get last ceiling light in the scene
                let newNightLight = lastNightLight.clone() // clone it into a new point light object
                newNightLight.position.x = lastNightLight.position.x + 1.78 // move new light by some factor from last
                nightLights.push(newNightLight) // add new light into array of all celing lights, at the end
                scene.add(newNightLight)
            }

            //removes wall and all objects on it
            deleteBtn.addEventListener('click', (event) => {

                wallSwitch = false;

                let deleteWall = wallsArray.find(x => x.id === wall.id)
                let wallIndex = wallsArray.indexOf(deleteWall)

                let deleteBtn = wall.group.getObjectByName('delete')
                scene.remove(deleteBtn)

                Livewire.dispatch('delete-cart-item', { wallSize: deleteWall.size, wallSwitched: deleteWall.group.userData.switched })

                // rearange rest of walls after the one being deleted
                if (wallsArray.length - 1 > wallIndex && wallsArray.length > 2) {
                    for (let i = wallIndex + 1; i <= wallsArray.length - 1; i++) {
                        wallsArray[i].group.position.x -= 1.9718 * deleteWall.size - 0.06

                        let bBox = new THREE.Box3().setFromObject(wallsArray[i].group)
                        bBox.applyMatrix4(scene.matrixWorld)

                        wallsArray[i].xMin = bBox.min.x
                        wallsArray[i].xMax = bBox.max.x
                    }
                }

                interactionManager.remove(wall.group);

                wallsArray.splice(wallIndex, 1);

                lastWall = wallsArray[wallsArray.length - 1]
                lastWallBoundingBox = new THREE.Box3().setFromObject(lastWall.group)
                lastWallBoundingBox.applyMatrix4(scene.matrixWorld)

                lastWallX = lastWall.xMax
                let lastWallCenterX = lastWallBoundingBox.min.x + (lastWallBoundingBox.max.x - lastWallBoundingBox.min.x) / 2
                let lastWallCenterY = lastWallBoundingBox.min.y + (lastWallBoundingBox.max.y - lastWallBoundingBox.min.y) / 2

                oControls.maxPan = new THREE.Vector3(lastWallBoundingBox.max.x - 1.9718, 0, 0);

                gsap.to(oControls.target, { x: lastWallCenterX, y: lastWallCenterY, duration: 1.5, ease: "power2.inOut" })
                gsap.to(camera.position, { x: lastWallCenterX, y: lastWallCenterY, duration: 1.5, ease: "power2.inOut" })

                gsap.to(rightProps.position, { x: rightProps.position.x - 1.9718 * deleteWall.size / 2, duration: 1.5, ease: "power2.inOut" })

                async function wallObjectsDeletion() {
                    let noOfChildren = deleteWall.group.children.length
                    for (let i = 0; i <= deleteWall.group.children.length - 1; i++) {
                        let child = deleteWall.group.children[i]
                        if (child.name === 'delete') {
                            deleteWall.group.remove(child)
                        }

                        if (child.name !== 'wall' && child.name !== 'delete') {
                            Livewire.dispatch('delete-cart-item', { removeItemId: parseInt(child.name), type: 'one', amount: 1, priceId: child.userData.priceId })
                            //deleteWall.group.remove(child)
                            await unloadSingleModel(deleteWall.id, 'wall')
                            dragInstance['drag' + child.id].dispose()
                        }



                        if (i === noOfChildren - 1) {
                            return new Promise(resolve => {
                                resolve()
                            })
                        }
                    }
                }

                wallObjectsDeletion().then((resolve) => {
                    updateClipPlane(lastWall.xMin, lastWall.xMax)

                })

                scene.remove(wall.group)

                // remove lights above deleted wall
                for (let i = 0; i < deleteWall.size; i++) {
                    let lastNightLight = nightLights[nightLights.length - 1] // get last ceiling light in the scene
                    nightLights.pop() // remove last light from the nightLights array
                    scene.remove(lastNightLight)
                }

            });

        },
        //function (xhr) { console.log((xhr.loaded / xhr.total * 100) + '% loaded'); },
        //function (error) { console.log('An error happened'); }
    );

    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, 2000);
    });
}

Livewire.on('addWall', ({ size: size }) => {
    loadWall(size)
})


// add clipping button object
let rightClipPlane
let topClipPlane
let clipBtn
let yClipBtn
let wallControlObjects = []
let wallBorder
let systemXValue = new Text()
let systemXMinValue
let systemXMaxValue
systemXValue.text = '200 cm'
systemXValue.fontSize = 0.075
systemXValue.fontWeight = 'bold'
systemXValue.color = 0x222222


function createClipBtn(xMin, xMax, wallGroup) {
    xMin += .06 // side borders
    if (!clipBtnExists) {

        wallBorder = wallGroup.getObjectByName('panel')
        rightClipPlane = [new THREE.Plane(new THREE.Vector3(-1, 0, 0), 0)]
        materials.panelSurface.clippingPlanes = rightClipPlane
        materials.panelSurface.clipIntersection = true
        materials.darkWoodSurface.clippingPlanes = rightClipPlane
        materials.darkWoodSurface.clipIntersection = true
        materials.lightWoodSurface.clippingPlanes = rightClipPlane
        materials.lightWoodSurface.clipIntersection = true

        rightClipPlane[0].constant = xMax + 0.06

        clipBtn = cutButtonModel.clone()
        clipBtn.position.x = xMax
        clipBtn.name = 'clipBtn'
        clipBtn.userData.limit = {
            min: new THREE.Vector3(xMin, 0.08, 0.2),
            max: new THREE.Vector3(xMax, 0.08, 0.2)
        }
        functions.updateModelLimits(clipBtn)

        objectsMoveConstraints.push(clipBtn);
        wallControlObjects.push(clipBtn)

        systemXMinValue = Math.floor((1.96 + xMin) / (measurements.baseUnit * 0.99)) * 100
        systemXMaxValue = Math.floor((2 + xMax) / (measurements.baseUnit * 0.99)) * 100

        scene.add(clipBtn)
        scene.add(systemXValue)

        let xxx = 19.55

        dragInstance['clip'] = new DragControls(wallControlObjects, camera, renderer.domElement);
        dragInstance['clip'].addEventListener('dragstart', function (event) {
            rightClipPlane[0].constant = functions.clamp(clipBtn.position.x, xMin, xMax);
            oControls.enablePan = false;
        })

        dragInstance['clip'].addEventListener('drag', function (event) {
            rightClipPlane[0].constant = functions.clamp(clipBtn.position.x, xMin, xMax);
            systemXValue.position.set(functions.clamp(clipBtn.position.x - .275, xMin - .275, xMax - .275), 2.1, .2)
            systemXValue.text = functions.clamp(Math.floor(((1.96 + clipBtn.position.x) / (measurements.baseUnit * 0.99)) * 100), systemXMinValue, systemXMaxValue) + ' cm'
            if (measurementsState) {
                measurements.updateMeasurements(systemXValue.text, rightClipPlane[0].constant)
            }

            // change cart item 2m panel to 1m panel and back, if long(2m) wall is cut beyond/above 100cm treshold
            let lastWall = wallsArray[wallsArray.length - 1]
            if (lastWall.size === 2) {
                let lastWallXvalue = Math.floor((2.2 + lastWall.xMax) / (measurements.baseUnit * 0.99)) * 100
                let xValue = functions.clamp(Math.floor(((1.96 + clipBtn.position.x) / (measurements.baseUnit * 0.99)) * 100), systemXMinValue, systemXMaxValue)

                //switch to 1m wall
                if ((lastWallXvalue - xValue) > 100 && wallSwitch === false) {
                    Livewire.dispatch('switchWall', { to: 'short' })
                    lastWall.group.userData.switched = true
                    wallSwitch = true;
                }

                // switch back to 2m wall
                if ((lastWallXvalue - xValue) < 100 && wallSwitch === true) {
                    Livewire.dispatch('switchWall', { to: 'long' })
                    lastWall.group.userData.switched = false
                    wallSwitch = false;
                }
            }



        })
        dragInstance['clip'].addEventListener('dragend', function (event) {
            rightClipPlane[0].constant = functions.clamp(clipBtn.position.x, xMin, xMax);
            oControls.enablePan = true;
        })
    }
}

// dynamic clipping plane on the last wall
function updateClipPlane(xMin, xMax, wallGroup) {
    xMin += .06
    systemXMinValue = Math.floor((2.2 + xMin) / (measurements.baseUnit * 0.99)) * 100
    systemXMaxValue = Math.floor((2.2 + xMax) / (measurements.baseUnit * 0.99)) * 100

    gsap.to(rightClipPlane[0],
        {
            constant: xMax,
            duration: 1.5,
            delay: 0,
            ease: "power2.inOut"
        })
    //rightClipPlane[0].constant = functions.clamp(xMax, xMin, xMax);

    // custom limit data for clipBtn for animation's sake
    clipBtn.userData.limit = {
        min: new THREE.Vector3(-100, 0.08, 0.2),
        max: new THREE.Vector3(100, 0.08, 0.2)
    }
    functions.updateModelLimits(clipBtn)

    setTimeout(function () {
        clipBtn.userData.limit = {
            min: new THREE.Vector3(xMin, 0.08, 0.2),
            max: new THREE.Vector3(xMax, 0.08, 0.2)
        }
        functions.updateModelLimits(clipBtn)
    }, 1500)


    gsap.to(clipBtn.position, {
        x: xMax,
        duration: 1.5,
        ease: "power2.inOut"
    })

    gsap.to(systemXValue.position, {
        x: xMax - .4,
        duration: 1.5,
        ease: "power2.inOut"
    })

    gsap.to(systemXValue, {
        text: systemXMaxValue,
        duration: 1.5,
        ease: "power2.inOut",
        onUpdate: function () {
            systemXValue.text = Math.floor(systemXValue.text) + ' cm'
            if (measurementsState) {
                measurements.updateMeasurements(systemXValue.text, rightClipPlane[0].constant)
            }

        }
    })

    dragInstance['clip'].addEventListener('dragstart', function (event) {
        rightClipPlane[0].constant = functions.clamp(clipBtn.position.x, xMin, xMax);
        oControls.enablePan = false;
    })
    dragInstance['clip'].addEventListener('drag', function (event) {
        rightClipPlane[0].constant = functions.clamp(clipBtn.position.x, xMin, xMax);
        systemXValue.position.set(functions.clamp(clipBtn.position.x - .4, xMin - .4, xMax - .4), 2.1, .2)
        systemXValue.text = functions.clamp(Math.floor(((1.96 + clipBtn.position.x) / (measurements.baseUnit * 0.99)) * 100), systemXMinValue, systemXMaxValue) + ' cm'
        if (measurementsState) {
            measurements.updateMeasurements(systemXValue.text, rightClipPlane[0].constant)
        }
    })
    dragInstance['clip'].addEventListener('dragend', function (event) {
        rightClipPlane[0].constant = functions.clamp(clipBtn.position.x, xMin, xMax);
        oControls.enablePan = true;
    })
}

// show hide initial 2m wall in cart
Livewire.on('toggleWallFromCart', ({ show }) => {
    if (show) {
        console.log(show)
        $(document.getElementsByClassName('cart_item')[0]).fadeIn(10)
    } else {
        console.log(show)
        $(document.getElementsByClassName('cart_item')[0]).fadeOut(10)
    }
})


// function to load and place models/objects on demand
let trueHover = false
let controlsHovered = false
function loadModel(model, product, params, offset, obj, colorVariation, priceId, originalObjId, configPositions, objSceneId, rotation = 1) { // rotation = 1 = up
    gLoader.load(
        model,
        function (gltf) {

            let objects = []

            objectsVisible = true;

            let object = gltf.scene

            object.userData.type = "model"
            object.userData.priceId = priceId
            object.userData.modelPath = model
            object.userData.params = params.toString()
            object.userData.rotation = rotation
            object.userData.switchMode = false

            if (model.includes('kolo') && !model.includes('vidula') && !model.includes('kolobezka_velka') && !model.includes('elektro') && !model.includes('dospele_kolo') && !model.includes('detske_kolo') && !model.includes('kolo_helma')) {
                object.userData.switchMode = true

                let modelPart = object.getObjectByName('kolo_rotate')

                if (object.userData.rotation === -1) {
                    modelPart.rotation.z = -modelPart.rotation.z
                }
            }

            if (model.includes('dospele_kolo') || model.includes('kolobezka_velka')) {
                object.userData.switchMode = true

                let modelPart = object.getObjectByName('kolo_rotate_y')

                if (object.userData.rotation === 1) {
                    modelPart.rotation.y = 0
                }

                if (object.userData.rotation === -1) {
                    modelPart.rotation.y = Math.PI
                }
            }

            object.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    if (child.name.indexOf('metal') !== -1) {
                        if (model.indexOf('store') !== -1) {
                            object.userData.colorVariation = colorVariation ? colorVariation : 'black'
                        } else {
                            object.userData.colorVariation = colorVariation ? colorVariation : globalColorVariation
                        }

                        let metalMaterial = new THREE.MeshPhysicalMaterial({
                            color: object.userData.colorVariation === 'steel' ? 0xF3F0EA : 0x2B2B2C,
                            metalness: 1,
                            reflectivity: 0.85,
                            roughness: .22,
                            ior: 1.7
                        })

                        child.material = metalMaterial;
                    }
                    if (child.name.indexOf('rubber') !== -1) {
                        object.userData.colorVariation = colorVariation ? colorVariation : globalColorVariation

                        let rubberMaterial = new THREE.MeshPhysicalMaterial({
                            color: object.userData.colorVariation === 'steel' ? 0x000000 : 0x7F786A,
                        })

                        child.material = rubberMaterial;
                    }
                    if (child.name === 'model') {
                        child.material = materials.objectMat;
                        gsap.to(child.material, { opacity: 1, duration: 0.4 })
                    }
                    if (child.name.indexOf('hbox') !== -1) {
                        child.material = materials.hoverMat;
                    }
                    if (child.name.indexOf('fortis') !== -1) {
                        child.material = materials.fortisMat;
                    }
                    if (child.name.indexOf('tag') !== -1) {
                        child.material = materials.tagMaterial;
                    }
                    if (child.name.indexOf('blackmet') !== -1) {
                        child.material = materials.blackMetal;
                    }
                    if (child.name.indexOf('plastic') !== -1) {
                        object.userData.colorVariation = colorVariation ? colorVariation : 'white'

                        let plasticColor
                        let plasticTransmission = 0
                        if (object.userData.colorVariation === 'white') { plasticColor = 0xBEBEBE }
                        if (object.userData.colorVariation === 'grey') { plasticColor = 0x666666 }
                        if (object.userData.colorVariation === 'black') { plasticColor = 0x000000 }
                        if (object.userData.colorVariation === 'red') { plasticColor = 0xff0000 }
                        if (object.userData.colorVariation === 'blue') { plasticColor = 0x00197E }
                        if (object.userData.colorVariation === 'graphite') { plasticColor = 0x111111 }
                        if (object.userData.colorVariation === 'transparent') { plasticColor = 0xffffff, plasticTransmission = 0.1 }

                        let plasticMaterial = new THREE.MeshPhysicalMaterial({
                            color: plasticColor,
                            reflectivity: .6,
                            roughness: 0.25,
                            clearcoat: .15,
                            clearcoatRoughness: 0.15,
                            transmission: plasticTransmission
                        })

                        child.material = plasticMaterial;
                    }
                    if (child.name === 'mic') {
                        child.material.roughness = .6;
                        child.material.metalness = 0;
                        gsap.fromTo(child.rotation, { y: 2 }, { y: 0, duration: 1, ease: "power3.out" })
                    }
                    if (child.name.indexOf('m_met') !== -1) {
                        child.material = materials.objMetal;
                    }
                    if (child.name.indexOf('plastorange') !== -1) {
                        child.material = materials.plasticOrangeMat;
                    }
                    if (child.name.indexOf('m_met_dark') !== -1) {
                        child.material = materials.objMetalDark;
                    }
                    if (child.name.indexOf('m_rub') !== -1) {
                        child.material = materials.objRubber;
                    }
                    if (child.name.indexOf('m_white') !== -1) {
                        child.material = materials.objWhite;
                    }
                    if (child.name.indexOf('m_bike_body') !== -1) {
                        child.material = materials.objDark;
                    }
                    if (child.name.indexOf('m_kufr_body') !== -1) {
                        child.material = materials.kufrBodyMat;
                    }
                    if (child.name !== 'hbox') {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }

                }
            });

            let objectDefaultZ = .14475

            let scaleFactor = 1.05
            object.scale.set(scaleFactor, scaleFactor, scaleFactor)

            // pass product iD as object name
            object.name = product.toString()
            object.obj = obj

            // get object bounding box and width for proper placement from right side of the wall
            let objectBox = new THREE.Box3().setFromObject(object)
            objectBox.applyMatrix4(scene.matrixWorld)
            let objectWidth = Math.abs(objectBox.min.x) + Math.abs(objectBox.max.x)

            // set move contraints on canvas - unique for each model/object - altered by active wall size

            object.userData.limit = {
                min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ)
                //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ)
            };
            functions.updateModelLimits(object)

            // instert into constraints array for live position updates
            objectsMoveConstraints.push(object);

            // instert into draggable array
            objects.push(object);
            addedObjects.push(object);

            let hoverTime

            // creates unique dragControls instance that can be targeted for dispose upon model unload
            dragInstance['drag' + object.id] = new DragControls(objects, camera, renderer.domElement);
            dragInstance['drag' + object.id].transformGroup = true

            let isDragging = false;

            dragInstance['drag' + object.id].addEventListener('dragstart', function (event) {
                Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })

                oControls.enablePan = false;

                if (!mobile) {
                    $("#object_controls_container").removeClass('active');
                }

                if (mobile) {
                    showMobileControls()
                }

                object.userData.limit = {
                    min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ + 0.05),
                    max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ + 0.05)
                    //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ + 0.05)
                };
                functions.updateModelLimits(object)
            });

            dragInstance['drag' + object.id].addEventListener('drag', function (event) {


                isDragging = true;
                $("#object_controls_container").removeClass('active');


            });

            dragInstance['drag' + object.id].addEventListener('dragend', function (event) {

                // turn camera pan on
                oControls.enablePan = true;

                // show object controls bar on mobile
                if (mobile) {
                    showMobileControls()
                }

                // update object positioning limits
                object.userData.limit = {
                    min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                    max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ)
                    //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ)
                };
                functions.updateModelLimits(object)

                // snap to panel
                object.position.y = functions.snapToPanel(object.position.y) - 0.004

                // move object from one wall group to another wall group if applicable
                let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
                placeWall.group.add(object)
            });

            function showMobileControls() {
                Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })
                $("#object_controls_container").addClass('mobile-active')
            }

            if (!mobile) {
                dragInstance['drag' + object.id].addEventListener('hoveron', function (event) {
                    Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })

                    hoverTime = setTimeout(function () {
                        trueHover = true
                    }, 250)

                    if (!zoom) {
                        setTimeout(function () {
                            if (trueHover === true) {
                                $("#object_controls_container").css({ top: pointer.yNorm - 10, left: pointer.xNorm + 20 });

                                if (!placementInProgress) {
                                    $("#object_controls_container").addClass('active')
                                }

                                object.userData.limit = {
                                    min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                                    max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ + 0.05)
                                    //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ + 0.05)
                                };
                                functions.updateModelLimits(object)

                                gsap.fromTo(object.position, {
                                    z: 0,
                                },
                                    {
                                        z: 0.05,
                                        duration: .2
                                    })
                            }
                        }, 500)
                    }

                });
            }



            dragInstance['drag' + object.id].addEventListener('hoveroff', function (event) {

                clearTimeout(hoverTime);
                trueHover = false
                setTimeout(function () {
                    if (controlsHovered === false) {
                        $("#object_controls_container").removeClass('active');
                        Livewire.dispatch('model-deactivated')
                    }
                }, 300)

                gsap.fromTo(object.position, {
                    z: 0.05,
                },
                    {
                        z: 0,
                        duration: .2
                    })
                setTimeout(function () {
                    object.userData.limit = {
                        min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                        max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ)
                        //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ)
                    };
                    functions.updateModelLimits(object)
                }, 200)
            });

            if (!configPositions) {
                Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })
                placeObject(object, offset, originalObjId)
                scene.add(object);
            } else {
                object.position.x = configPositions[0]
                object.position.y = configPositions[1]
                object.position.z = configPositions[2]
                scene.add(object);
                configModelsLoaded++
            }


        },
        function (xhr) {
            ModelloadingInProgress = true
            Livewire.dispatch('model-loading', xhr.loaded / xhr.total * 100)
        },
        function (error) {
            //console.log(error);
        }
    );
}



$("#object_controls_container").on('mouseenter', () => {
    controlsHovered = true;
})

$("#object_controls_container").on('mouseleave', () => {
    $("#object_controls_container").removeClass('active');
    controlsHovered = false;
})

// initial placement function, snap to mouse position, release and snap to Y grid lines on click
let placementInProgress = false
function placeObject(object, offset, originalObjId) {
    placementInProgress = true
    let placing = true
    if (!mobile) {
        function followMouse() {
            if (placing === true) {
                var vector = new THREE.Vector3(pointer.x, pointer.y, 0.5);
                vector.unproject(camera);
                var dir = vector.sub(camera.position).normalize();
                var distance = - camera.position.z / dir.z;
                var pos = camera.position.clone().add(dir.multiplyScalar(distance));
                pos.z = .145 + 0.075
                object.position.copy(pos);
            }
        }

        window.addEventListener('mousemove', function (event) {
            event.preventDefault();
            followMouse()
        });

        //followMouse()

        window.addEventListener("mouseup", () => {
            if (placementInProgress === true) {
                object.position.y = functions.snapToPanel(object.position.y) - 0.004
            }

            placementInProgress = false

            if (placing === true) {
                // add object to group with active wall
                let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
                placeWall.group.add(object)
            }

            placing = false

        });
    } else {
        // placing duplicate model next to original
        if (originalObjId) {
            for (let i = 0; wallsArray.length; i++) {
                wallsArray[i].group.traverse(function (child) {
                    if (child.id === originalObjId) {
                        let tempBoundingBox = new THREE.Box3().setFromObject(child)
                        tempBoundingBox.applyMatrix4(scene.matrixWorld)

                        let originalObjectWidth = Math.abs(tempBoundingBox.min.x) + tempBoundingBox.max.x

                        object.position.x = child.position.x + originalObjectWidth + .2
                        object.position.y = functions.snapToPanel(child.position.y)

                        let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
                        placeWall.group.add(object)
                    }
                })
            }
        } else {
            object.position.y = functions.snapToPanel(0)
            object.position.x = 0
            let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
            placeWall.group.add(object)
        }

        placementInProgress = false

        placing = false
    }
}

let modelPartRotation = false
// rotate model function
Livewire.on('rotateModel', ({ id }) => {
    if (!modelPartRotation) {
        modelPartRotation = true
        let sceneModel = scene.getObjectById(id)

        if (sceneModel.getObjectByName('kolo_rotate')) {
            let modelPart = sceneModel.getObjectByName('kolo_rotate')

            gsap.fromTo(modelPart.rotation, {
                z: modelPart.rotation.z
            }, {
                z: -modelPart.rotation.z,
                duration: 1
            })
        }

        if (sceneModel.getObjectByName('kolo_rotate_y')) {
            let modelPart = sceneModel.getObjectByName('kolo_rotate_y')

            gsap.fromTo(modelPart.rotation, {
                y: modelPart.rotation.y
            }, {
                y: sceneModel.userData.rotation === 1 ? Math.PI : 0,
                duration: 1
            })
        }

        sceneModel.userData.rotation = -sceneModel.userData.rotation

        setTimeout(() => {
            modelPartRotation = false
        }, 1005);
    }


})

// delete single object/product
async function unloadSingleModel(id, source) {

    if (mobile) {
        $("#object_controls_container").removeClass('mobile-active');
    } else {
        $("#object_controls_container").removeClass('active');
    }

    objectControlsActive = false
    let noOfWalls = wallsArray.length

    for (let i = 0; i <= wallsArray.length - 1; i++) {
        wallsArray[i].group.traverse(function (child) {
            if (child.id === id) {
                dragInstance['drag' + id].dispose()
                wallsArray[i].group.remove(child)
                return new Promise(resolve => {
                    Livewire.dispatch('delete-process-finished')
                    resolve()
                });
            }
        })
    }

    Livewire.dispatch('delete-process-finished')
}

Livewire.on('remove-model', ({ id }) => {
    unloadSingleModel(id)
})

// delete all objects containing certain product
let childrenToRemove = [];
function unloadAllModels(name, priceId) {
    Livewire.dispatch('delete-process-finished')
    if (wallsArray.length > 1) {
        for (let i = 0; i <= wallsArray.length - 1; i++) {
            wallsArray[i].group.children.forEach(child => {
                if (child.name === name && child.userData.priceId === priceId) {
                    childrenToRemove.push(child);
                }
            });
            childrenToRemove.forEach(child => {
                wallsArray[i].group.remove(child);
                dragInstance['drag' + child.id].dispose()
            });
        }
        childrenToRemove = []
    } else {
        wallsArray[0].group.children.forEach(child => {

            if (child.name === name && child.userData.priceId === priceId) {
                childrenToRemove.push(child);
            }
        });
        childrenToRemove.forEach(child => {
            wallsArray[0].group.remove(child);
            dragInstance['drag' + child.id].dispose()
        });
        childrenToRemove = []
    }

    $(".detail_container").removeClass('active');


}

Livewire.on('remove-models', ({ id, priceId }) => {
    unloadAllModels(id.toString(), priceId)
})

function resetCart() {
    Livewire.dispatch('delete-process-finished')
    for (let i = 0; i <= wallsArray.length - 1; i++) {
        wallsArray[i].group.children.forEach(child => {
            if (child.name !== 'panel') {
                childrenToRemove.push(child);
            }
        });
        childrenToRemove.forEach(child => {
            wallsArray[i].group.remove(child);
            dragInstance['drag' + child.id].dispose()
        });
    }

    $(".detail_container").removeClass('active');

}

Livewire.on('reset-cart', () => {
    resetCart()
})


const particles = new THREE.Group();
if (!mobile) {
    // dust particles
    const particleCount = 1500;


    const particleGeometry = new THREE.TetrahedronGeometry(0.0025, 0);
    const particleMaterial = materials.defaultMat

    for (let i = 0; i < particleCount; i++) {
        const particle = new THREE.Mesh(particleGeometry, particleMaterial);

        // Randomize particle positions within a cube
        particle.position.x = Math.random() * 10 - 5;
        particle.position.y = Math.random() * 10 - 5;
        particle.position.z = Math.random() * 10 - 5;

        particles.add(particle);
    }

    scene.add(particles);
}



// effect composer
// const composer = new EffectComposer(renderer)
// const renderPass = new RenderPass(scene, camera)
// composer.addPass(renderPass)
// const outputPass = new OutputPass();
// composer.addPass(outputPass);


let dt, lastframe = Date.now()
let zoomFactor;
let loadedModelsUpdated = false
// animate
function animate() {

    oControls.maxPan.y = functions.clamp(8.5 - camera.position.z * 1.25, 0, 1.5);
    oControls.minPan.y = -oControls.maxPan.y;

    oControls.minPan.x = functions.clamp(-(8.5 - camera.position.z * 1.25), -1.5, 0);

    if (lastWallX > 2) {
        oControls.maxPan.x = (lastWallX - 1.2) + (8.5 - camera.position.z * 1.25) / 3;
    } else {
        oControls.maxPan.x = (lastWallX - 1.925) + (8.5 - camera.position.z * 1.25) / 3;
    }


    interactionManager.update();
    lights.directLight.shadow.camera.updateProjectionMatrix();

    objectsMoveConstraints.forEach(o => {
        o.userData.update();
    })

    dt = (Date.now() - lastframe) / 1000

    if (mixer) {
        mixer.update(dt)
    }

    lastframe = Date.now()

    measurements.xTextValue.sync()
    measurements.yTextValue.sync()

    // Rotate particles
    // particles.rotation.x += 0.0005;
    // particles.rotation.y += 0.0005;

    // Update particle positions for subtle movement
    if (!mobile && particles) {
        particles.children.forEach((particle) => {
            particle.position.x += Math.sin(Date.now() * 0.0001) * 0.0005;
            particle.position.y += Math.cos(Date.now() * 0.0001) * 0.0005;
            particle.position.z += Math.sin(Date.now() * 0.0001) * 0.0005;
            particle.rotation.y += 0.005;
            particle.rotation.x += 0.005;

            // Wrap particles around the scene
            if (particle.position.x < -5 || particle.position.x > 5 ||
                particle.position.y < -5 || particle.position.y > 5 ||
                particle.position.z < -5 || particle.position.z > 5) {
                particle.position.x = Math.random() * 10 - 5;
                particle.position.y = Math.random() * 10 - 5;
                particle.position.z = Math.random() * 10 - 5;
            }
        });
    }

    // update loaded configuration models    
    if (configModelsCount > 0 && loadedModelsUpdated === false) {
        if (configModelsCount === configModelsLoaded) {
            if (wallsArray) {
                updateLoadedModelsWallGroup()
                loadedModelsUpdated = true
            }
        }
    }

    requestAnimationFrame(animate);
    scene.updateMatrixWorld()
    camera.updateProjectionMatrix();
    oControls.update();
    renderer.render(scene, camera);
    //composer.render();
}

animate();

// autoresize
window.addEventListener('resize', () => {
    viewport.width = window.innerWidth
    viewport.height = window.innerHeight
    camera.aspect = viewport.width / viewport.height
    camera.updateProjectionMatrix()
    renderer.setSize(viewport.width, viewport.height)
})

// UI functions ...
// $(document).on('click', '.panel_add_btn', function (e) {
//     $('.selection_part > .container, .panel_add_btn').toggleClass('active')
// });


Livewire.on('play-intro-animation', () => {

    configurationToLoad ? 0 : 5.25
    // after loading is complete
    //$('#circles_container > img').addClass('active')
    setTimeout(function () {
        $('.inner_circle').addClass('introAnimation')
    }, 5000)

    gsap.to('.inner_circle', { width: '60%', opacity: 1, duration: .8, ease: "back.out(3)", delay: configurationToLoad ? 0 : 5.25 }) // delay 6.25
    gsap.to('.outer_circle', { width: '82%', opacity: 1, duration: .8, ease: "back.out(3)", delay: configurationToLoad ? 0 : 5.5 })
    gsap.to('.button_rotation_container', { opacity: 1, duration: .2, delay: configurationToLoad ? 0 : 5.5 }) // 6.5
    //gsap.to('.object_select_container', { left: '0%', duration: .8, ease: "power3.out", delay: configurationToLoad ? 0 : 4.25 })
    if (!mobile) {
        gsap.fromTo('.cart_component', {
            right: '0px',
            opacity: 0
        }, {
            right: '48px',
            opacity: 1,
            duration: .8,
            ease: "power3.out",
            delay: configurationToLoad ? 0 : 5.75
        })
    }

    gsap.fromTo('.inquiry_btn', {
        right: '2%',
        opacity: 0
    }, {
        right: '4.6%',
        opacity: 1,
        duration: .8,
        ease: "power3.out",
        delay: configurationToLoad ? 0 : 6
    })

    gsap.fromTo('.entry-button', {
        y: '16px',
        opacity: 0
    }, {
        y: '0px',
        opacity: 1,
        duration: 0.8,
        ease: "power3.out",
        delay: 6.75
    })
    gsap.from('.scene_controller_ui', { bottom: '-7.5vw', duration: .8, ease: "power3.out", delay: configurationToLoad ? 0 : 5.75 })
})

// adding objects to the scene
let activatedProduct
$(document).on('click', '.object_btn', function (e) {
    e.preventDefault()
    let model = $(this).data('model')
    let obj = $(this).data('obj')
    let product = $(this).data('product')
    let params = $(this).data('params').split(", ")
    let offset = $(this).data('offset')
    let loadBar = $(this).children('load_bar')
    let priceId = $(this).data('priceid')
    loadModel(model, product, params, offset, obj, null, priceId)
    activatedProduct = product
    ModelloadingInProgress = true
})

Livewire.on('add-duplicate', ({ model: model, product: product, parameters: parameters, offset: offset, obj: obj, colorVariation: colorVariation, priceId: priceId, originalObjId: originalObjId }) => {
    $("#object_controls_container").removeClass('active');
    model = 'images/' + model
    parameters = parameters.split(", ")
    offset = parseFloat(offset)
    loadModel(model, product, parameters, offset, obj, colorVariation, priceId, originalObjId)
    activatedProduct = product
    ModelloadingInProgress = true
})

Livewire.on('model-loading', (progress) => {
    $('#model_load_indicator').addClass('active')
    $('#indicator').width(progress + '%')
    if (progress == 100) {
        setTimeout(function () {
            $('#indicator').addClass('loaded')
            $('#model_load_indicator').removeClass('active')
            ModelloadingInProgress = false
        }, 250)
        setTimeout(function () {
            $('#indicator').width('0%')
            $('#indicator').removeClass('loaded')
            ModelloadingInProgress = false
        }, 1000)
    }
})

Livewire.on('mini-model-loading', (progress) => {
    $('#mini_model_load_indicator').addClass('active')
    $('#mini_indicator').width(progress + '%')
    if (progress == 100) {
        setTimeout(function () {
            $('#mini_indicator').addClass('loaded')
            $('#mini_model_load_indicator').removeClass('active')
            ModelloadingInProgress = false
        }, 250)
        setTimeout(function () {
            $('#mini_indicator').width('0%')
            $('#mini_indicator').removeClass('loaded')
            ModelloadingInProgress = false
        }, 1000)
    }
})

window.addEventListener('pointermove', onPointerMove);

$("#object_controls_container").on('mouseenter', (e) => {
    objectControlsActive = true
})

$("#object_controls_container").on('mouseleave', (e) => {
    objectControlsActive = false
})

document.body.addEventListener('mousemove', (e) => {
    oControls.handleMouseMoveRotate(e)
});

window.addEventListener('moving', function (event) {
    if (!mobile) {
        $("#object_controls_container").css({ top: pointer.yNorm - 10, left: pointer.xNorm + 20 });
    }
});

// zoom to model
let zoom = false
let zoomObjectId = null
let originalCameraPosition
let zoomToObject
Livewire.on('zoom-to-model', ({ id }) => {

    let bbox

    if (id !== null) { zoomToObject = scene.getObjectById(id) }

    if (zoom === false) {

        dragInstance['drag' + zoomToObject.id].deactivate()

        originalCameraPosition = camera.position.x

        oControls.maxPan = new THREE.Vector3(lastWallX + 3, 2, 8.75);
        oControls.minPan = new THREE.Vector3(-2, -2, 0);
        oControls.enablePan = true;
        oControls.minAzimuthAngle = -(Math.PI / .2) // 20
        oControls.maxAzimuthAngle = (Math.PI / .2)  // 20

        zoomToObject.children.forEach(parent => {
            if (parent.name === 'metal' || parent.name.indexOf('plastic') !== 0) {
                bbox = new THREE.Box3().setFromObject(parent);
                bbox.applyMatrix4(scene.matrixWorld)
            }
            parent.children.forEach(child => {
                if (child.name === 'metal' || child.name.indexOf('plastic') !== 0) {
                    bbox = new THREE.Box3().setFromObject(child);
                    bbox.applyMatrix4(scene.matrixWorld)
                }
            });
        });

        //toggleObjects(false)

        zoomInTimeline(bbox.max.x - 0.15, bbox.max.y - 0.11, bbox.max.z - 0.2, 1)
        gsap.to(camera, { fov: 25, duration: 1 })

        $('.zoomOut_btn').addClass('active')
        $("#object_controls_container").removeClass('active');
        $(".detail_container").addClass('active');
        $(".object_select_container").css({ left: '-18%' });
        $(".button_rotation_container, #circles_container").addClass('zoom');

        zoomObjectId = zoomToObject.name

        zoom = !zoom

    } else {

        dragInstance['drag' + zoomToObject.id].activate()

        gsap.to(camera, { fov: 35, duration: 1.5, ease: "Power2.easeInOut" })


        gsap.to(oControls.minPan, {
            x: 0,
            y: 0,
            z: 0,
            duration: 2,
            ease: "Power2.easeInOut"
        })

        gsap.to(oControls.maxPan, {
            x: lastWallX - 1.9718,
            y: 0,
            z: 0,
            duration: 2,
            ease: "Power2.easeInOut"
        })

        zoomInTimeline(originalCameraPosition, 0, 0, 8.75)

        oControls.enablePan = true;


        //toggleObjects(true)



        setTimeout(function () {
            oControls.minAzimuthAngle = -(Math.PI / 20) // 20
            oControls.maxAzimuthAngle = (Math.PI / 20)  // 20

        }, 1500);

        $('.zoomOut_btn').removeClass('active')
        $(".object_select_container").css({ left: '0%' });
        $(".detail_container").removeClass('active');
        $(".button_rotation_container, #circles_container").removeClass('zoom');

        zoomObjectId = null

        zoom = !zoom
    }
})

function zoomInTimeline(x, y, z, zoomOutFactor = 0) {
    gsap.to(camera.position, { x: x + 1, y, z: z + zoomOutFactor, duration: 1.5, ease: "Power2.easeInOut" })
    gsap.to(oControls.target, { x, y, z, duration: 1.5, ease: "Power2.easeInOut" })

};

$("#zoom_info_btn").on('click', function (e) {
    e.preventDefault()
    Livewire.dispatch('view-detail', { id: zoomObjectId })
})

function toggleObjects(state) {
    for (let i = 0; i < addedObjects.length; i++) {
        addedObjects[i].traverse(function (child) {
            if (child instanceof THREE.Mesh) {
                if (child.name.indexOf('metal') !== 0 && child.name.indexOf('rubber') !== 0 && child.name.indexOf('fortis') !== 0 && child.name.indexOf('plastic') !== 0 && child.name.indexOf('tag') !== 0) {
                    child.visible = state
                }
            }
        })
    }

    setTimeout(function () {
        objectsVisible = state
    }, 100);
}

$('.hideObjs').on('click', (e) => {
    e.preventDefault()
    if (objectsVisible === true) {
        toggleObjects(false)
    } else if (objectsVisible === false) {
        toggleObjects(true)
    }
})

$('#main_add_btn').on('click', (e) => {
    if (mobile) {
        $("#object_controls_container").removeClass('mobile-active');
        $("#scene_controller_ui").removeClass('active');
        Livewire.dispatch('model-deactivated')
    }
})

$('.zoomOut_btn').on('click', (e) => {
    e.preventDefault()
    Livewire.dispatch('zoom-to-model', { id: null })
})

let nightMode = false
let sunDown = new THREE.Color().setHex(0xFFA200);
let sunRise = new THREE.Color().setHex(0xFFDC9E);
let dayInteriorColor = new THREE.Color().setHex(0xffffff);
let nightInteriorColor = new THREE.Color().setHex(0x111111);

// night lights
scene.add(lights.pointLight1);
nightLights.push(lights.pointLight1)

scene.add(lights.pointLight2);
nightLights.push(lights.pointLight2)

scene.add(lights.pointLight3);
nightLights.push(lights.pointLight3)

$('.light_mode_btn').on('click', (e) => {
    e.preventDefault()

    if (nightMode === false) {
        let power = 700

        nightLights.forEach(function (nightLight) {
            gsap.to(nightLight, { power: power, duration: 1, delay: .8, ease: 'Power3.out' })
        });

        // gsap.to(lights.directLight.color, { r: sunDown.r, g: sunDown.g, b: sunDown.b, duration: 1 })

        gsap.to(renderer, { toneMappingExposure: 2, duration: 1 })
        gsap.to(lights.directLight, { intensity: 0, duration: 1 })
        gsap.to(lights.directLight.position, { y: 0, duration: 1 })
        gsap.to(lights.hemiLight, { intensity: 0, duration: 1 })
        gsap.to('#circles_container', { opacity: .4, duration: 1 })

        gsap.to(materials.interiorMat.color, {
            r: nightInteriorColor.r,
            g: nightInteriorColor.g,
            b: nightInteriorColor.b,
            duration: 1
        })

        setTimeout(function () {
            nightMode = true
        }, 1000);
    }

    if (nightMode === true) {
        let power = 0

        nightLights.forEach(function (nightLight) {
            gsap.to(nightLight, { power: power, duration: 1, ease: 'Power3.out' })
        });

        gsap.to(lights.hemiLight, { intensity: 1.4, duration: 1 })
        gsap.to(lights.directLight, { intensity: 4, duration: 1 })
        gsap.to(lights.directLight.position, { y: 1.25, duration: 1 })
        //gsap.to(lights.directLight.color, { r: sunRise.r, g: sunRise.g, b: sunRise.b, duration: 1 })
        gsap.to(renderer, { toneMappingExposure: 3.85, duration: 1 })
        gsap.to('#circles_container', { opacity: 1, duration: 1 })

        gsap.to(materials.interiorMat.color, {
            r: dayInteriorColor.r,
            g: dayInteriorColor.g,
            b: dayInteriorColor.b,
            duration: 1
        })

        setTimeout(function () {
            nightMode = false
        }, 1000);
    }
})

// $(document).on('mouseenter', '.cart_item', function (e) {
//     Livewire.dispatch('detail-update', { id: $(this).data('id') })
//     $(".detail_container").addClass('active');
// })

// $(document).on('mouseleave', '.cart_item', function (e) {
//     $(".detail_container").removeClass('active');
// })

$(document).on('click', '.miniModel_color_btn', function (e) {
    e.preventDefault()
    let colorName = $(this).data('name');
    let tempColor = new THREE.Color().setHSL(0, 0, 0);
    tempColor.setHex('0x' + $(this).data('color'));
    let rubberTempColor = new THREE.Color().setHSL(0, 0, 0);
    rubberTempColor.setHex('0x' + $(this).data('rubber'));

    miniModel.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.name.indexOf('metal') !== -1) {
                gsap.to(child.material.color, {
                    r: tempColor.r,
                    g: tempColor.g,
                    b: tempColor.b,
                    duration: .2
                })
            }
            if (child.name.indexOf('rubber') !== -1) {
                gsap.to(child.material.color, {
                    r: rubberTempColor.r,
                    g: rubberTempColor.g,
                    b: rubberTempColor.b,
                    duration: .2
                })
            }
        }
    });
})

Livewire.on('change-color-variation', ({ objectId, colorName, priceId }) => {

    let objectToUpdate = scene.getObjectById(objectId);
    objectToUpdate.userData.colorVariation = colorName;
    objectToUpdate.userData.priceId = parseInt(priceId);

    let metalTempColor = new THREE.Color().setHSL(0, 0, 0);
    let rubberTempColor = new THREE.Color().setHSL(0, 0, 0);
    let plasticTempColor = new THREE.Color().setHSL(0, 0, 0);


    if (colorName === 'steel') {
        metalTempColor.setHex('0xF3F0EA');
        rubberTempColor.setHex('0x000000');
    }

    if (colorName === 'black') {
        metalTempColor.setHex('0x2B2B2C');
        rubberTempColor.setHex('0x7F786A');
        plasticTempColor.setHex('0x000000');
    }

    if (colorName === 'white') {
        plasticTempColor.setHex('0xBEBEBE');
    }

    if (colorName === 'graphite') {
        plasticTempColor.setHex('0x111111');
    }

    if (colorName === 'grey') {
        plasticTempColor.setHex('0x666666');
    }

    if (colorName === 'red') {
        plasticTempColor.setHex('0xff0000');
    }

    if (colorName === 'blue') {
        plasticTempColor.setHex('0x00197E');
    }

    if (colorName === 'transparent') {
        plasticTempColor.setHex('0xffffff');
    }

    objectToUpdate.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.name.indexOf('metal') !== -1) {
                gsap.to(child.material.color, {
                    r: metalTempColor.r,
                    g: metalTempColor.g,
                    b: metalTempColor.b,
                    duration: .2
                })
            }
            if (child.name.indexOf('rubber') !== -1) {
                gsap.to(child.material.color, {
                    r: rubberTempColor.r,
                    g: rubberTempColor.g,
                    b: rubberTempColor.b,
                    duration: .2
                })
            }
            if (child.name.indexOf('plastic') !== -1) {
                gsap.to(child.material.color, {
                    r: plasticTempColor.r,
                    g: plasticTempColor.g,
                    b: plasticTempColor.b,
                    duration: .2
                })
                if (colorName === 'transparent') { child.material.transmission = 0.8 } else { child.material.transmission = 0 }
            }
        }
    })
    //Livewire.dispatch('update-color-variation', { product_id: objectToUpdate.name, new_price_id: priceId, variation_color: colorName, variation_id: variationId })
})

// fullscreen control
let fullscreenMode = false
$(".fullscreen_btn, .fs-ico").on('click', () => {
    if (!fullscreenMode) {
        fullscreen.openFullscreen()
    } else {
        fullscreen.closeFullscreen()
    }
    fullscreenMode = !fullscreenMode
})

// measurements display
let measurementsState = false
$('.measure_btn').on('click', function (e) {
    e.preventDefault()

    let center
    let wallsLength
    let xMin
    let xMax

    if (wallsArray.length > 1) {
        xMin = wallsArray[0].xMin
        xMax = wallsArray[wallsArray.length - 1].xMax
        center = ((Math.abs(xMin) + xMax) / 2) - 1 - Math.abs(xMin) / 2
        wallsLength = Math.abs(xMin) + xMax
    } else {
        center = 0
        xMin = wallsArray[0].xMin
        xMax = wallsArray[0].xMax
        wallsLength = Math.abs(xMin) + xMax
    }

    if (!measurementsState) {
        measurements.getMeasurements(scene, 'get', center, systemXValue.text, xMin, xMax, rightClipPlane[0].constant)
        measurementsState = true
    } else {
        measurements.resetMeasurements()
        measurementsState = false
    }
})

//screenshot capture
$('.screenshot_btn').on('click', function () {

    let zFactor
    let center

    if (wallsArray.length > 1) {
        let xMin = wallsArray[0].xMin
        let xMax = wallsArray[wallsArray.length - 1].xMax
        center = ((Math.abs(xMin) + xMax) / 2) - 1 - Math.abs(xMin) / 2
        zFactor = (Math.abs(xMin) + xMax) * 0.36
        zoomInTimeline(center, 0.2, 0, 7 + zFactor)
    } else {
        zoomInTimeline(0, 0.2, 0, 7)
    }

    // zoom-in animations
    gsap.to(oControls, {
        minAzimuthAngle: 0,
        maxAzimuthAngle: 0,
        duration: 1.5,
        ease: "Power2.easeInOut"
    })



    // effects animations
    let frameDuration = 1.2
    let frameDelay = .8
    let frameEasing = "elastic.out(1.2,0.5)"
    gsap.to('#main_ui', { opacity: 0, scale: 1.05, duration: .5 })
    gsap.to('#screenshot_container', { opacity: 1, duration: .1 })
    gsap.to('.top_left', { top: '80px', left: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.top_right', { top: '80px', right: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.bottom_right', { bottom: '80px', right: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.bottom_left', { bottom: '80px', left: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .outer', { scale: 1, opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .inner', { rotation: 0, opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.flash', { opacity: .1, duration: 0.1, delay: frameDelay + .6 })
    gsap.to('.flash', { opacity: 0, duration: 0.1, delay: frameDelay + .65 })
    gsap.to('#screenshot_container', { opacity: 0, duration: .5, delay: frameDelay + 1.3 })
    gsap.to('#main_ui', { opacity: 1, scale: 1, duration: .5, delay: frameDelay + 2 })
    gsap.to('.top_left', { top: '160px', left: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('.top_right', { top: '160px', right: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('.bottom_right', { bottom: '160px', right: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('.bottom_left', { bottom: '160px', left: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .outer', { scale: .4, opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .inner', { rotation: 90, opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })

    // taking screenshot
    setTimeout(function () {
        screenshot.saveAsImage(renderer)
    }, 1500)

    // zoom-out animations
    setTimeout(function () {
        gsap.to(oControls, {
            minAzimuthAngle: -(Math.PI / 20),
            maxAzimuthAngle: (Math.PI / 20),
            duration: 1.5,
            ease: "Power2.easeInOut"
        })

        if (wallsArray.length > 1) {
            zoomInTimeline(center, 0, 0, 8.5)
        } else {
            zoomInTimeline(0, 0, 0, 8.5)
        }
    }, 2000)
})

// console debug mode switch
let DEBUG = false;
if (!DEBUG) {
    if (!window.console) window.console = {};
    let methods = ["log", "debug", "warn", "info"];
    for (let i = 0; i < methods.length; i++) {
        console[methods[i]] = function () { };
    }
}

// reset file input on form
$(document).on('click', '.reset_files', function (e) {
    const fileInput = document.getElementById("upload");
    fileInput.value = null;
})

if (window.innerWidth < 630) {
    window.matchMedia("(orientation: landscape)").addEventListener("change", e => {
        const landscape = e.matches;
        if (landscape) {
            $('.mobile-landscape-modal').removeClass('active')
        } else {
            $('.mobile-landscape-modal').addClass('active')
        }
    });
}

$('.order-action, .inquiry_btn').on('click', function () {
    $('#window, #lead_modal').addClass('order-view')
})

$('.form-close-btn').on('click', function () {
    setTimeout(function () {
        $('#window, #lead_modal').removeClass('order-view')
    }, 300)
})

// function to get url parameters

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};


function saveConfiguration(type) {
    let sceneData = [] // final array object
    let sceneWalls = []
    let sceneObjects = []
    let sceneConfig = {}

    // get data about walls in the scene -> final array position: 0
    wallsArray.forEach(wall => {
        let wallArray = {
            'size': wall.size
        }
        sceneWalls.push(wallArray)
    })
    sceneData.push(sceneWalls)

    // get data about objects in the scene -> final array position: 1
    for (let i = 0; i <= wallsArray.length - 1; i++) {
        wallsArray[i].group.children.forEach(child => {
            if (child.name !== 'wall' && child.name !== 'delete' && child.name !== 'Scene') {
                let objectArray = {
                    'name': child.name, // product/shop id
                    'objId': child.obj, // db obj_id
                    'objSceneId': child.id,
                    'priceId': child.userData.priceId,
                    'path': child.userData.modelPath,
                    'colorVariation': child.userData.colorVariation,
                    'params': child.userData.params,
                    'posX': child.position.x,
                    'posY': child.position.y,
                    'posZ': child.position.z,
                    'rotation': child.userData.rotation
                }
                sceneObjects.push(objectArray)
            }
        });
    }
    sceneData.push(sceneObjects)

    // scene config object -> final array position: 2
    sceneConfig = {
        'lights': nightLights.length,
        'rightPropsX': rightProps.position.x,
        'clipPlaneX': rightClipPlane[0].constant,
        'maxPan': oControls.maxPan.x,
        'panelColor': materials.panelSurface.color.getHexString(),
        'panelColorName': $('#panel-color-name').text()
    }
    sceneData.push(sceneConfig)

    Livewire.dispatch('save-configuration', { sceneData: sceneData, type: type })
}

$('.submit-configuration').on('click', function (e) {
    e.preventDefault()
    console.log()
    if ($('#conf_terms').is(":checked") && $('#conf_password').val !== '' && $('#conf_email').val !== '') {
        saveConfiguration('new')
    }
})

$(document).on('click', '.submit-auth-configuration', function (e) {
    e.preventDefault()
    saveConfiguration('authorized')
})

$(document).on('click', '.submit-updated-configuration', function (e) {
    e.preventDefault()
    saveConfiguration('updated')
})

// Get configuration data from db
function getConfigurationData(id) {
    Livewire.dispatch('get-configuration-data', { configId: id })
}

// Process configuration data and fill scene

Livewire.on('process-configuration-data', async ({ configData }) => {
    setTimeout(() => {
        setupSceneConfig(configData)
            .then((resolve) => configPanelColor(configData[2].panelColor, configData[2].panelColorName))
            .then((resolve) => loadConfigModels(configData))
    }, 1200);
})

// Partial configuration process function -> load and place all models
async function loadConfigModels(configData) {
    configModelsCount = configData[1].length
    configData[1].forEach(model => {
        let params = model.params.split(",")

        model.rotation ? model.rotation : 1

        let configPositions = [model.posX, model.posY, model.posZ]
        loadModel(model.path, parseInt(model.name), params, 0, model.objId, model.colorVariation, model.priceId, null, configPositions, model.objSceneId, model.rotation)
        activatedProduct = parseInt(model.name)
    })
    return new Promise(resolve => { resolve() });
}

// Partial configuration process function -> setup walls into the scene -> after that return promise to setup models
async function setupSceneConfig(configData) {

    // setup walls -> we do not want to work with the first wall if there are more walls, thus i = 1
    for (let i = 1; i < configData[0].length; i++) {
        await loadWall(configData[0][i].size).then((resolve) => {
            if (i + 1 === configData[0].length) {
                return new Promise(resolve => {
                    setTimeout(() => {
                        resolve();
                    }, 0);
                });
            }
        })
    }

    rightProps.position.x = configData[2].rightPropsX

    // setup camera maxPan if there are more walls in the scene
    if (configData[0].length > 1) {
        oControls.enablePan = true;
    }
}

// add loaded models to their wallgroups
function updateLoadedModelsWallGroup() {
    scene.traverse(function (child) {
        if (child.userData.type === 'model') {
            setTimeout(() => {
                let placeWall = wallsArray.find(wall => wall.xMin < child.position.x && wall.xMax > child.position.x)
                placeWall.group.add(child)
            }, 1000);
        }
    });
}

// Partial configuration process function -> change walls color
async function configPanelColor(color, name) {

    let tempColor = new THREE.Color().setHSL(0, 0, 0);
    tempColor.setHex('0x' + color);
    scene.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.name.indexOf('panel') !== -1) {
                if (color === '989ea2') { // dark wood
                    child.material = materials.darkWoodSurface
                }
                if (color === '989ea3') { // light wood
                    child.material = materials.lightWoodSurface
                }
                if (color !== '989ea3' && color !== '989ea2') {
                    gsap.to(child.material.color, {
                        r: tempColor.r,
                        g: tempColor.g,
                        b: tempColor.b,
                        duration: .25
                    })
                }
            }
        }
    });

    $('#color-preview-ico').css('background-color', "#" + color)
    $('#panel-color-name').text(name)
    Livewire.dispatch('config-cart-panel-color', { colorName: name })

    return new Promise(resolve => { resolve() });
}

// copy configuration link to clipboard
const copyLinkContent = async (elementId) => {
    let text = document.getElementById(elementId).innerHTML;
    try {
        await navigator.clipboard.writeText(text);
        $('.copy-btn').css('backgroundColor', 'black')
        $('.copy-btn').html('hotovo')
    } catch (err) {

    }
}

$(document).on('click', '.copy-btn', function (e) {
    copyLinkContent('link-text')
})

$('.copy-btn').on('mouseleave', function () {
    $('.copy-btn').css('backgroundColor', '#2740b8')
    $('.copy-btn').html('kopírovat')
})

Livewire.on('new-configuration-created', ({ hash }) => {

    Livewire.dispatch('authorize-user')
    Alpine.store('auth').toggle()
    Alpine.store('confNo').val = hash
    Livewire.dispatch('get-conf-number', { confNumber: hash })

    configurationHash = hash
    userAuthorized = true

    window.history.pushState("object or string", "ConfigCreate", "/?config=" + hash)

    configurationsArray.push(hash)

    sessionStorage.setItem('configurations', JSON.stringify(configurationsArray))
    sessionStorage.setItem('authorization', '1')
})

Livewire.on('new-auth-configuration-created', ({ hash }) => {

    // Livewire.dispatch('authorize-user')
    // Alpine.store('auth').toggle()

    Alpine.store('confNo').val = hash
    Livewire.dispatch('get-conf-number', { confNumber: hash })

    configurationHash = hash
    userAuthorized = true

    window.history.pushState("object or string", "ConfigCreate", "/?config=" + hash)

    configurationsArray.push(hash)

    sessionStorage.setItem('configurations', JSON.stringify(configurationsArray))
    sessionStorage.setItem('authorization', '1')
})

$(document).on('click', '.search-btn', function (e) {
    document.getElementById("uiSearchField").focus();
});


// mobile menu toggle
$(document).on('click', '#mobile-menu-btn', function (e) {
    $("#scene_controller_ui").toggleClass('active');
    $("#object_controls_container").removeClass('mobile-active');
});

$(document).on('click', '#cart_component, #mobile-zoom-in, #mobile-zoom-out, .panel_add_container', function (e) {
    $("#object_controls_container").removeClass('mobile-active');
});

if (mobile) {
    $("#order_btn").text('Koupit');
}

// mobile zoom function
if (mobile) {
    $(document).on('click', '#mobile-zoom-in', function (e) {
        camera.rotation.y = 45
        camera.position.z -= 1
    });
    $(document).on('click', '#mobile-zoom-out', function (e) {
        camera.position.z += 1
    });
}

window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    e.returnValue = "";
});

// save reminder function
setTimeout(function () {
    remindSave()
}, 300000);

function remindSave() {
    if (configurationHash === '' && !Alpine.store('conForm').on) {
        $('#save-reminder-modal-container').addClass('active')
    }
}

$('.reminder-modal-cta, .close-reminder-modal, .conf-form-close-btn').on('click', function () {
    $('#save-reminder-modal-container').removeClass('active')
    setTimeout(function () {
        remindSave()
    }, 300000);
})

window.addEventListener('click', function (event) {
    $('.entry-button').css('display', 'none')
});
























// stats.js
// (function () { var script = document.createElement('script'); script.onload = function () { var stats = new Stats(); document.body.appendChild(stats.dom); requestAnimationFrame(function loop() { stats.update(); requestAnimationFrame(loop) }); }; script.src = 'https://mrdoob.github.io/stats.js/build/stats.min.js'; document.head.appendChild(script); })()





