function saveAsImage(renderer) {
    let imgData

    try {
        let strMime = "image/jpeg";
        let strDownloadMime = "image/octet-stream";

        imgData = renderer.domElement.toDataURL(strMime);

        saveFile(imgData.replace(strMime, strDownloadMime), "Reponio_moje-konfigurace.jpg");

    } catch (e) {
        console.log(e);
        return;
    }

}

let saveFile = function (strData, filename) {
    let link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); //Firefox requires the link to be in the body
        link.download = filename;
        link.href = strData;
        link.click();
        document.body.removeChild(link); //remove the link when done
    } else {
        location.replace(uri);
    }
}

export { saveAsImage }
